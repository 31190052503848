const jQuery = window.jQuery
const $ = jQuery

require('@bower_components/jquery-validation')
require('@bower_components/magnific-popup')
require('@bower_components/responsive-tabs')
;(function() {
  var CustomSearch,
    __bind = function(fn, me) {
      return function() {
        return fn.apply(me, arguments)
      }
    },
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) {
      for (var key in parent) {
        if (__hasProp.call(parent, key)) child[key] = parent[key]
      }
      function ctor() {
        this.constructor = child
      }
      ctor.prototype = parent.prototype
      child.prototype = new ctor()
      child.__super__ = parent.prototype
      return child
    }

  CustomSearch = (function() {
    function CustomSearch(searchSubmitSelector, searchQuerySelector, searchResultHolderSelector) {
      var _this = this
      this.searchSubmitSelector = searchSubmitSelector
      this.searchQuerySelector = searchQuerySelector
      this.customSearchEngineId = $(searchResultHolderSelector).data('engine-id')
      this.searchResultHolderSelector = searchResultHolderSelector
      this.onNextPage = __bind(this.onNextPage, this)
      this.onSearchResultReady = __bind(this.onSearchResultReady, this)
      this.onExecuteSearch = __bind(this.onExecuteSearch, this)
      jQuery(this.searchSubmitSelector).click(this.onExecuteSearch)
      if (jQuery(this.searchQuerySelector).val() !== '') {
        this.onExecuteSearch()
      }
      this.pageSize = 10
      jQuery(this.searchQuerySelector).keydown(function(e) {
        if (e.keyCode === 13) {
          return _this.onExecuteSearch()
        }
      })
    }

    CustomSearch.prototype.onExecuteSearch = function() {
      this.customSearchEngineId = this.customSearchEngineId || '010314335279624818652:oaxua4w2ra0'
      this.searchQuery = jQuery(this.searchQuerySelector).val()
      jQuery(this.searchResultHolderSelector).empty()
      this.currentPageIndex = 1
      return this.getSearchResults({
        cx: this.customSearchEngineId,
        alt: 'json',
        q: this.searchQuery,
        start: this.currentPageIndex
      })
    }

    CustomSearch.prototype.getSearchResults = function(data) {
      if (jQuery(this.searchResultHolderSelector).length > 0) {
        data.key = 'AIzaSyDKOKjguaEWgFkINQ6BAFLjHIROO6niWXw'
        return jQuery.getJSON('https://www.googleapis.com/customsearch/v1?callback=?', data, this.onSearchResultReady)
      }
    }

    CustomSearch.prototype.onSearchResultReady = function(data) {
      if (data.searchInformation) {
        var resultsFoundHtml = data.searchInformation.totalResults

        jQuery('.number-of-results-found').html(resultsFoundHtml)
      }

      if (data.items) {
        this.displaySearchResults(data.items)
        if (data.queries.nextPage) {
          return this.displayNextPageLink()
        }
      } else {
        this.displaySearchResultsNotFound()
      }
    }

    CustomSearch.prototype.displaySearchResults = function(searchResults) {
      var html, image, searchResult, searchResultHolder, _i, _len, _results
      searchResultHolder = jQuery(this.searchResultHolderSelector)
      _results = []
      for (_i = 0, _len = searchResults.length; _i < _len; _i++) {
        searchResult = searchResults[_i]

        // language=HTML
        var html =
          '<tr onclick="location.href = \'' +
          searchResult.link +
          '\';">\n            <td>\n              <p class="commontable-title commontable-title--nowrap grid-gap0-below">' +
          searchResult.htmlTitle +
          '</p>\n              <a href="#">' +
          searchResult.link +
          '</a><br/>\n              ' +
          searchResult.snippet +
          '\n            </td>\n          </tr>'

        _results.push(searchResultHolder.append(html))
      }

      $('.commontable a').trunk8({
        lines: 1
      })

      return _results
    }

    CustomSearch.prototype.displaySearchResultsNotFound = function() {
      var html, searchResultHolder
      searchResultHolder = jQuery(this.searchResultHolderSelector)
      var noResultsText = searchResultHolder.data('no-results-text')
      // language=HTML
      html =
        '<div class="grid-row grid-text-center grid-gap8-above grid-gap5-below listing-noresults">\n          <div class="grid-span12">\n            <h2>' +
        noResultsText +
        '</h2>\n          </div>\n        </div>'
      return searchResultHolder.append(html)
    }

    CustomSearch.prototype.displayNextPageLink = function() {
      var nextPage, searchResultHolder
      searchResultHolder = jQuery(this.searchResultHolderSelector)
      var moreResultsText = searchResultHolder.data('more-results-text')
      // language=HTML
      var html =
        '<div class="next-page grid-row grid-gap5-above grid-desktop-gap4-above grid-tablet-gap3-above grid-mobile-gap2-above">\n          <div class="grid-span12 grid-text-center">\n            <a href="" class="button button--large">' +
        moreResultsText +
        '</a>\n          </div>\n        </div>'
      nextPage = jQuery(html)
      searchResultHolder.append(nextPage)
      return nextPage.click(this.onNextPage)
    }

    CustomSearch.prototype.onNextPage = function() {
      var searchResultHolder
      searchResultHolder = jQuery(this.searchResultHolderSelector)
      searchResultHolder.find('.next-page').remove()
      this.currentPageIndex += this.pageSize
      this.getSearchResults({
        cx: this.customSearchEngineId,
        alt: 'json',
        q: this.searchQuery,
        start: this.currentPageIndex
      })
      return false
    }

    return CustomSearch
  })()

  jQuery(function() {
    return (window.customSerach = new CustomSearch('.search-submit', '#search', '#search-results'))
  })
}.call(this))

$(function() {
  /*##################
  Language Picker
  ##################*/

  const dismissedCookieID = require('./_languagesuggestion.js').dismissedCookieID
  const Cookies = require('js-cookie')

  $('.language-picker').on('change', function() {
    Cookies.set(dismissedCookieID, true)
    location.href = $(this).val()
  })

  $('.mobilemenu-links-languagechange').on('click', function() {
    Cookies.set(dismissedCookieID, true)
  })

  /*##################
  Hero Video
  ##################*/

  $('.hero-video-toggle').click(function() {
    var heroVideo = $('.hero-video')
    var iframe = heroVideo.find('iframe')

    var closeIframe = false
    if ($(this).hasClass('hero-video-toggle--close')) {
      closeIframe = true
    } else {
      iframe.attr('src', iframe.data('src') + '&autoPlay=1')

      $('html, body').animate({
        scrollTop: 0
      })
    }

    heroVideo.fadeToggle(1000, function() {
      if (closeIframe) {
        iframe.attr('src', '')
      }
    })

    return false
  })

  /*##################
  Hero scroll effects
  ##################*/

  if ($('.hero--scrolleffect').length > 0) {
    let initialHeight = parseInt($('.hero--scrolleffect').css('height'), 10)
    $(window).scroll(function() {
      updateHero()
    })

    $(window).on('resize', function() {
      setTimeout(() => {
        $('.hero--scrolleffect').css('height', '')
        initialHeight = parseInt($('.hero--scrolleffect').css('height'), 10)
        updateHero()
      }, 200)
    })

    function updateHero() {
      let distance = $(window).scrollTop() - $('.hero-scrollwrapper').offset().top
      distance = distance > 0 ? distance : 0
      distance = distance > initialHeight ? initialHeight : distance
      const scrolledHeight = initialHeight - distance

      const scrolledTop = $(window).scrollTop()
      const limit = $('.hero-scrollwrapper').offset().top + initialHeight
      if (scrolledTop > limit) {
        $('.hero--scrolleffect').height(initialHeight)
        $('.hero-scrollwrapper').css('padding-top', 0)
        return
      }

      $('.hero--scrolleffect').height(scrolledHeight)
      $('.hero-scrollwrapper').css('padding-top', distance)

      let opacity = 1 - distance / (initialHeight - 300)
      opacity = opacity < 0 ? 0 : opacity
      $('.hero--scrolleffect .hero-content .hero-content-scrollfade').css('opacity', opacity)
    }
  }

  /*##################
  Curtain scroll effects
  ##################*/

  if ($('.curtain').length > 0) {
    $(window).on('load resize scroll', function() {
      $('.curtain').each(function(index, e) {
        const viewportTop = $(window).scrollTop()
        const viewportBottom = viewportTop + $(window).height()
        const distanceTop = $(e).offset().top
        const offset = -120
        const inView = Math.max(viewportBottom - distanceTop, 0)
        const inViewPart = Math.min(inView / ($(window).height() * 0.6), 1)
        const newOffset = (1 - inViewPart) * offset
        const opacity = inViewPart

        $(e)
          .find('.curtain-item--left')
          .css('left', newOffset)
        $(e)
          .find('.curtain-item--right')
          .css('right', newOffset)
        $(e)
          .find('.curtain-item')
          .css('opacity', opacity)
      })
    })
  }

  /*##################
  Fade scroll effect
  ##################*/

  if ($('.fadeitem').length > 0) {
    $(window).on('load resize scroll', function() {
      $('.fadeitem').each(function(index, e) {
        const viewportTop = $(window).scrollTop()
        const viewportBottom = viewportTop + $(window).height()
        const distanceTop = $(e).offset().top
        const inView = Math.max(viewportBottom - distanceTop, 0)
        const inViewPart = Math.min(inView / ($(window).height() * 0.4), 1)
        const opacity = inViewPart

        $(e).css('opacity', opacity)
      })
    })
  }

  /*##################
  Show/Hide phone number UR+
  ##################*/

  $('#phonenumber-toggle').click(function() {
    $('.phonenumber-hidden').toggle()
    $(this).toggleClass('active')
    return false
  })

  /*##################
  Disable Menu Links on tablets in horizontal mode
  ##################*/

  var isMobile = false //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  )
    isMobile = true

  if (isMobile) {
    $('.menu-item-link-disablemobile').addClass('helper-disabledlink')
  }

  /*##################
  3D toggle
  ##################*/

  var openedOnce = false

  $('.toggle-3d').click(function() {
    var container3d = $('#container-3d')

    if (!openedOnce) {
      openedOnce = true

      $('<iframe />', {
        src: $(this).data('url'),
        class: 'product-iframe'
      }).appendTo(container3d)
    }

    $('.toggle-3d-text').toggle()
    container3d.fadeToggle()

    return false
  })

  /*##################
  Table
  ##################*/

  $('.rte table').each(function(index) {
    if ($(this).find('thead').length) {
      $(this).addClass('fancytable')
    }
  })

  $('.collapseitem-content table').addClass('commontable')

  /*##################
  File Item
  ##################*/

  $('.fileitem>select').change(function() {
    $(this)
      .parent()
      .find('form>input.file')
      .val($(this).val())
    $(this)
      .siblings('.button')
      .removeClass('button--disabled')
  })

  $('.fileitem button.download').click(function() {
    var url = $(this)
      .parent()
      .siblings('select.chosen-file')
      .val()
    window.open(url, '_blank')
  })

  /*##################
  Dropdown
  ##################*/

  $('.dropdown-button').click(function() {
    var dropdown = $(this).parent('.dropdown')

    if (dropdown.hasClass('dropdown--active')) {
      $('.dropdown').removeClass('dropdown--active')
    } else {
      $('.dropdown').removeClass('dropdown--active')
      dropdown.toggleClass('dropdown--active')
    }

    return false
  })

  $('.dropdown-content-wrapper a').click(() => {
    $('.dropdown--active').removeClass('dropdown--active')
  })

  var eventType = navigator.userAgent.match(/iPad/i) ? 'touchstart' : 'click'
  $('html, body').on(eventType, function(e) {
    if ($(e.target).closest('.dropdown').length === 0) {
      $('.dropdown').removeClass('dropdown--active')
    }
  })

  $('.dropdown .formcheckbox input').change(function() {
    var checkbox = $(this)
    var type = checkbox.data('type')

    if (!type) {
      return // Skip this one
    }

    if (type.indexOf('-master') >= 0) {
      $("[data-type='" + type.replace('-master', '') + "']").prop('checked', checkbox.is(':checked'))
    } else {
      if (!checkbox.is(':checked')) {
        $("[data-type='" + type + "-master']").prop('checked', false)
      } else if ($("[data-type='" + type + "']").not(':checked').length === 0) {
        $("[data-type='" + type + "-master']").prop('checked', true)
      }
    }
  })

  /*##################
  Search
  ##################*/

  $('#search-form').on('submit', function() {
    return false
  })

  /*##################
  Trunkate
  ##################*/

  $('.post-content').trunk8({
    lines: 1
  })
  $(window).resize(function(event) {
    $('.post-content').trunk8({
      lines: 1
    })
  })

  /*##################
  Collapse item
  ##################*/

  var resizeCollapseItems = function() {
    var collapseItem = $('.collapseitem')
    collapseItem.width('100%')
    collapseItem.width(Math.round(collapseItem.width()))
  }

  $(window).resize(resizeCollapseItems)
  resizeCollapseItems()

  $('.collapseitem-header').click(function() {
    $('.collapseitem').toggleClass('collapseitem--expanded')
    $('.collapseitem-content').slideToggle(200)
  })

  /*##################
  Slick
  ##################*/

  $('.slideshow').on('init', function(event, slick, direction) {
    // check to see if there are one or less slides
    if (!($('.slick-slider .slick-slide').length > 1)) {
      // remove arrows and dots
      $('.slider__arrow').hide()
      $('.slick-dots').hide()
      $(this)
        .find('.slick-dotted')
        .removeClass('slick-dotted')
    }
  })

  // the slideshow needs to have more than one item to have active dots
  $('.slideshow:not(.slideshow--mobile)').each(function() {
    const jq = $(this)
    const showDots = jq.find('>*').length > 1
    $(this).slick({
      dots: showDots
    })
  })

  $('.caseslideshow').slick({
    dots: true
  })

  // the following code caused videos to start, when navigating to next slide
  // $('.slideshow').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
  //   var slide = slick.$slides.get(currentSlide);
  //   var iframe = $(slide).find('iframe')
  //   $.each(iframe, function(index, element) {
  //     element.src = element.src
  //   })
  // })

  initMobileSlideshow()

  $(window).resize(function() {
    initMobileSlideshow()
  })

  function initMobileSlideshow() {
    if ($('.slideshow.slideshow--mobile').hasClass('slick-initialized') === false) {
      if (isMobileWidth() === true) {
        $('.slideshow.slideshow--mobile').slick({
          autoplay: true
        })
      }
    } else {
      if (isMobileWidth() === false) {
        $('.slideshow.slideshow--mobile').slick('unslick')
      }
    }
  }

  /*##################
  Odometer
  ##################*/

  $('.odometer').each(function(index) {
    const value = $(this).data('value')
    $(this).html(value)
  })

  /*##################
  Slider
  ##################*/

  $('.slider').slider({
    range: 'min',
    min: 0,
    max: 5,
    step: 1,
    classes: {
      'ui-slider-handle': 'slider-handle'
    }
  })

  /*##################
  Jquery validate
  ##################*/

  $.validator.addMethod('robotserialnumber', function(value, element) {
    const jq = $(element)
    const validationValid = jq.val().match(/^[0-9]{11}$/)
    const validationSuccessful = this.optional(element) || validationValid
    return validationSuccessful
  })

  $.validator.addMethod('customemail', function(value, element) {
    return (
      this.optional(element) ||
      $(element)
        .val()
        .match(
          /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f}}\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]{{0-4][0-9])|1[0-9][0-9]{{1-9]?[0-9]))\.){3}(?:(2(5[0-5]{{0-4][0-9])|1[0-9][0-9]{{1-9]?[0-9]){{a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f}}\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
        )
    ) // the customer provided this regex 🤷‍♂️
  })

  const validateThis = function(obj) {
    $(obj).data('validator', null)
    $(obj).unbind('validate')

    $(obj).validate({
      ignore: '.ember-checkbox, .possibly-required:hidden, .g-recaptcha-response', // ignore hidden inputs, i.e. selects are still NOT ignored

      rules: {
        password: {
          minlength: 4
        },
        oldPassword: {
          minlength: 4
        },
        confirmPassword: {
          minlength: 4,
          equalTo: 'input[name="password"]'
        },
        confirmPasswordSpecific: {
          minlength: 4,
          equalTo: 'input[name="password"]#password'
        },
        newPassword: {
          minlength: 4,
          equalTo: 'input[name="password"]'
        }
      },

      errorPlacement: function(error, element) {
        const label = element.siblings('.formitem-label')
        label.hide()

        if ($(element).attr('type') === 'checkbox') {
          error.insertAfter($(element).closest('.formcheckbox'))
        } else if (element.is('select')) {
          const chosen = element.siblings('.chosen-container')
          const isChosen = $('.chosen-container').length > 0
          //error.css({ position: 'relative', top: '-15px' })
          error.insertAfter(isChosen ? chosen : element)
          chosen.addClass('error')
        } else if (label.length > 0) {
          error.insertAfter(label)
          error.parent('.formitem').removeClass('formitem--approved')
        } else {
          error.insertAfter(element)
          error.parent('.formitem').removeClass('formitem--approved')
        }
      },
      success: function(error, element) {
        const label = error.siblings('.formitem-label')
        label.show()

        if ($(element).is('select')) {
          const chosen = error.siblings('.chosen-container')
          chosen.removeClass('error')
        } else {
          const container = error.parent('.formitem')
          container.addClass('formitem--approved')
        }

        error.remove()
      }
    })
  }

  window.validateThis = validateThis

  $('.validate').each(function() {
    validateThis(this)
  })

  /*##################
  Form selects
  ##################*/

  window.chosenBrowserIsSupported = function() {
    if (/iP(od|hone)/i.test(window.navigator.userAgent)) {
      return false
    }
    if (/Android/i.test(window.navigator.userAgent)) {
      if (/Mobile/i.test(window.navigator.userAgent)) {
        return false
      }
    }
    if (/IEMobile/i.test(window.navigator.userAgent)) {
      return false
    }
    if (/Windows Phone/i.test(window.navigator.userAgent)) {
      return false
    }
    if (/BlackBerry/i.test(window.navigator.userAgent)) {
      return false
    }
    if (/BB10/i.test(window.navigator.userAgent)) {
      return false
    }
    if (window.navigator.appName === 'Microsoft Internet Explorer') {
      return document.documentMode >= 8
    }
    return true
  }

  window.initializeChosen = function() {
    $('select').on('change', function() {
      setTimeout(() => {
        $(this).trigger('chosen:updated')
      }, 1)
    })

    $('select').chosen({
      disable_search_threshold: 10,
      inherit_select_classes: true
    })

    $('select.chosen-show')
      .chosen()
      .change(function() {
        var $this = $(this)
        const $selectedOption = $this.find('option:selected')

        var showElement = $selectedOption.data('show')
        var hideElement = $selectedOption.data('hide')

        $(showElement).show()
        $(hideElement).hide()
      })

    if (!window.chosenBrowserIsSupported()) {
      $('select').each(function() {
        var placeholder = $(this).data('placeholder')

        var option = $(this)
          .find('option')
          .first()
        option.val(0)
        option.text(placeholder)
        option.attr('disabled', true)
        option.attr('selected', 'selected')
      })
    }
  }

  // bugfix chosen scroll bug
  const focusMethod = jQuery.fn['focus']
  jQuery.fn['focus'] = function() {
    if (this.hasClass('chosen-search-input') === false) {
      focusMethod.apply(this, arguments)
    }
  }

  window.initializeChosen()

  /*##################
  Amountbox
  ##################*/

  $(document).on('input', '.amountbox-input', function() {
    var value = $(this).val()
    if (value > 0) {
      $(this)
        .closest('.amountbox')
        .addClass('amountbox--selected')
    } else {
      $(this)
        .closest('.amountbox')
        .removeClass('amountbox--selected')
    }
  })

  /*##################
  Tooltip for product
  ##################*/

  $('.product').tooltip({
    classes: {
      'ui-tooltip': 'highlight tooltip'
    },
    items: $('.product-hotspot'),
    position: { my: 'left+17 top+55', at: 'right bottom' },
    show: false,
    hide: false,
    content: function() {
      $('.tooltip-source .tooltip-title').text($(this).data('title'))
      $('.tooltip-source .tooltip-content').text($(this).data('content'))
      return $('.tooltip-source')
    }
  })

  /*##################
  linkitem hover
  ##################*/

  $('.linkitem-icon')
    .on('mouseover', function() {
      if (!isMobileWidth()) {
        $('.linkitem-text').trunk8({
          lines: 3
        })
      }
      $(this)
        .closest('.linkitem')
        .addClass('linkitem--active')
    })
    .on('mouseout', function() {
      $(this)
        .closest('.linkitem')
        .removeClass('linkitem--active')
    })

  $(window).resize(function(event) {
    if (isMobileWidth()) {
      $('.linkitem-text').trunk8('revert')
    }
  })

  /*##################
  Media query check
  ##################*/

  function isMobileWidth() {
    return $('.mediaquery--mobile').is(':visible')
  }

  /*##################
    Tabs
  ##################*/

  $(function() {
    $('.tabs').responsiveTabs({
      show: 'slideDown',
      hide: 'slideUp',
      animation: 'slide',
      activate: function(event, tab) {
        $(tab.panel)
          .find('.slideshow')
          .each(function(index) {
            $(this).slick('refresh')
          })
      }
    })
  })

  /*##################
    Mapmenu height calulator
  ##################*/

  function setMapmenuHeight() {
    var mapmenuHeaderHeight = $('.mapmenu-header').outerHeight()
    var mapmenuHeight = $('.mapmenu').outerHeight()
    var remainingHeight = mapmenuHeight - mapmenuHeaderHeight
    $('.mapmenu-locations').height(remainingHeight)
  }

  $(window).resize(function() {
    setMapmenuHeight()
  })

  /*##################
  Tooltip
  ##################*/

  $('.factbar').tooltip({
    classes: {
      'ui-tooltip': 'highlight tooltip tooltip--shadow tooltip--large'
    },
    content: function() {
      return $(this).data('tooltip')
    },
    items: '[data-tooltip]',
    position: { my: 'center top+10', at: 'center bottom' },
    show: 'fadeIn',
    hide: 'fadeOut'
  })

  $('.marketleaderbox').tooltip({
    classes: {
      'ui-tooltip': 'tooltip tooltip--theme'
    },
    content: function() {
      const toolImage = $(this).data('image')
      $('.tooltip-source .tooltip-image').attr('src', toolImage)
      $('.tooltip-source .tooltip-title').text($(this).data('title'))
      $('.tooltip-source .tooltip-content').text($(this).data('content'))
      return $('.tooltip-source')
    },
    items: $('.tooltip-trigger'),
    position: { my: 'center bottom', at: 'center top-10' },
    show: 100,
    hide: false
  })

  /*##################
  Overlay
  ##################*/

  $('a[data-modal]').click(function(event) {
    const element = $(this)
    const identifier = element.data('modal')

    // first check for classes
    const $modalByClasses = element.next('.' + identifier)

    if ($modalByClasses.length > 0) {
      window.openDialogElement(element.next('.' + identifier))
      return false
    }

    // then check for ids
    const $modalById = $('#' + identifier)
    if ($modalById.length > 0) {
      window.openDialog('#' + identifier)
      return false
    }

    if (element.attr('href') !== '' && element.attr('href') !== '#') {
      return true
    }

    console.error('could not open modal')
    return false
  })

  window.openDialog = function(selecter) {
    window.openDialogElement($(selecter))
  }

  window.openDialogElement = function(element) {
    $('.helper-hide-after-hubspot-submit').show()

    if ($(element).hasClass('overlay-blocker') || $(element).hasClass('overlay')) {
      element.fadeIn(200)
      $('body').css('overflow', 'hidden')
    }
  }

  var doclose = true
  var closeDialog = function() {
    if (doclose) {
      $('body').css('overflow', 'visible')
      $('.overlay-blocker').fadeOut(200)

      // reload any video to stop them from playing
      $('iframe').attr('src', function(i, val) {
        return val
      })

      setTimeout(function() {
        $('.form-success-message').remove()
        $('.overlay-blocker form').show()
      }, 1000)
    }

    doclose = true
  }

  window.closeDialog = closeDialog

  $('.overlay-blocker')
    .click(closeDialog)
    .children()
    .click(function(e) {
      doclose = false
    })
  $('.overlay-close, .overlay-closelink').click(closeDialog)

  /*##################
  HubSpot Form Submit
  ##################*/

  const recaptchaErrorClass = '.recaptcha-error'

  window.correctCaptcha = () => {
    $(recaptchaErrorClass).addClass('grid-hidden')
  }

  window.validateRecaptcha = (formElement) => {
    // hopefully recaptcha is loaded at this point in time
    if (window.grecaptcha) {
      const $form = $(formElement)
      const recaptchaElementId = $form.find('.g-recaptcha').attr('id')
      const recaptchaWidgetId = window[recaptchaElementId]

      const isValid = window.grecaptcha.getResponse(recaptchaWidgetId).length > 0

      const $recaptchaErrorMessage = $form.find(recaptchaErrorClass)

      if (isValid) {
        $recaptchaErrorMessage.addClass('grid-hidden')
      } else {
        $recaptchaErrorMessage.removeClass('grid-hidden')
      }

      return isValid
    }

    return true
  }

  $('form.hubspot-form').submit(function() {
    var $form = $(this)
    var successMessage = $form.data('message')
    var redirectURL = $form.data('redirect')
    var action = $form.attr('action')
    var data = $form.serialize()

    let somethingWentWrong = false

    if (!$form.valid()) {
      somethingWentWrong = true
    }

    if (validateRecaptcha(this) === false) {
      somethingWentWrong = true
    }

    // either the jquery validate or recaptcha went wrong, don't submit
    if (somethingWentWrong) {
      return false
    }

    const doNormalSubmit = $(this).hasClass('do-normal-submit')
    if (doNormalSubmit) {
      $form.parent().append($.parseHTML('<div class="form-success-message">' + successMessage + '</div>'))
      $form.hide()
      return
    }

    const formButton = $form.find('button.button')
    const formLoading = $form.find('.form-loading')
    formButton.hide()
    formLoading.show()

    $.ajax({
      type: 'POST',
      url: action,
      data: data,
      success: function(result) {
        formButton.show()
        formLoading.hide()
        $('.helper-hide-after-hubspot-submit').hide()
        if (redirectURL.length > 0) {
          window.location.href = redirectURL
        } else if (result && result.message) {
          $form.parent().append($.parseHTML('<div class="form-success-message">' + result.message + '</div>'))
          $form.hide()
        } else if (result && result.redirectUrl) {
          window.location = result.redirectUrl
        } else {
          $form.parent().append($.parseHTML('<div class="form-success-message">' + successMessage + '</div>'))
          $form.hide()
        }
      }
    }).fail(function() {
      formButton.show()
      formLoading.hide()
      alert('Error - please try again') // This should never happen
    })

    return false
  })

  /*##################
  Email specifications
  ##################*/

  $('.fileitem')
    .find('form')
    .submit(function() {
      const $form = $(this)
      if (!$form.valid()) {
        return
      }

      var objectifyForm = function(formArray) {
        var returnArray = {}
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value']
        }
        return returnArray
      }

      const data = objectifyForm($form.serializeArray())

      data.file = $form
        .children('select.chosen-file')
        .chosen()
        .val()
      data.hq_language_downloaded_material = $form
        .find('select.hq_language_downloaded_material option:selected')
        .text()
        .trim()
      data.hq_action_on_product_page = 'E-mail' //$(this).find('select.hq_action_on_product_page option:selected').text().trim()

      // note: this is defined by universal robots. We hardcore it so that users do not have to specify it on every single page
      window.hubspotTrack('b60b6734-5d5d-4ed3-9f85-dd989aabe2c8', data)

      // const method = $(this).find('select.chosen-method').chosen().val()
      // if (method !== '2') { // '2' specifies that the user has selected email
      //   return false // do not submit form
      // }

      const formButton = $form.find('button.button')
      const formLoading = $form.find('.form-loading')
      formButton.hide()
      formLoading.show()

      $.ajax({
        type: 'POST',
        url: '/umbraco/surface/Products/SendFile',
        data: data,
        success: function(response) {
          $('#filebox-success').fadeIn(200)
          $('body').css('overflow', 'hidden')
          formButton.show()
          formLoading.hide()
        }
      }).fail(function(response) {
        alert('Failed to send file. Please try again')
        formButton.show()
        formLoading.hide()
      })

      return false
    })

  /*##################
  Blog styling fix
  ##################*/

  var updateForm = function() {
    $('.hs-button').addClass('button')

    var $checkbox = $('input[type="checkbox"].hs-input')
    $checkbox
      .parent('label')
      .parent('li')
      .addClass('formcheckbox')
    $checkbox
      .next('span')
      .first()
      .wrap('<label for="subscribe"></label>')
    $checkbox.siblings('label').prepend('<span>')
    $checkbox.unwrap()
    $checkbox.attr('id', 'subscribe')

    $('#subscribe')
      .find('input[type="submit"]')
      .addClass('button')
  }

  var updateFormChecker = function() {
    var $checkbox = $('input[type="checkbox"].hs-input')
    var $subscribeButton = $('#subscribe').find('input[type="submit"]')

    if ($checkbox.length + $subscribeButton.length === 0) {
      setTimeout(updateFormChecker, 100)
    } else {
      updateForm()
    }
  }

  updateFormChecker()

  /*##################
  Dynamic height (ratio aspect)
  ##################*/

  const findAndResizeDynamicHeightElements = function() {
    $.each($('[data-height-ratio]'), function(index, element) {
      const $element = $(element)
      $element.css({ height: $element.width() * $element.data('height-ratio') })
    })
  }

  $(window).resize(findAndResizeDynamicHeightElements)
  findAndResizeDynamicHeightElements()

  /*##################
  Contentbox/blogmenu height fix
  ##################*/

  var contentBoxResize = function() {
    $('.contentbox-container, .blogmenu-container').each(function(indexO, elementO) {
      var boxes = $(elementO).find('.contentbox, .blogmenu')

      boxes.css({ height: 'auto' })

      var maxHeight = -1
      boxes.each(function(indexI, elementI) {
        var height = $(elementI).outerHeight()
        var width = $(elementI).outerWidth()
        if (!(width > $('body').outerWidth() * 0.9)) {
          maxHeight = height > maxHeight ? height : maxHeight
        }
      })

      if (maxHeight > 0) {
        boxes.css({ height: maxHeight + 'px' })
      }
    })
  }

  $(window).resize(contentBoxResize)
  contentBoxResize()
  setTimeout(contentBoxResize, 500)

  /*##################
  Developer create account
  ##################*/

  $('.toggle').change(function() {
    const isChecked = $(this).is(':checked')

    if (isChecked) {
      $(this)
        .data('toggle')
        .split(' ')
        .forEach((element2) => {
          $(element2)
            .prop('checked', false)
            .change()
        })
    }

    $(this)
      .data('toggle-visibility')
      .split(' ')
      .forEach((element) => {
        if (isChecked) {
          $(element).slideDown()
        } else {
          $(element).slideUp()
        }
      })
  })

  $('#companyYes, #companyNo').change(function() {
    $('input[type=checkbox]:not(#companyYes):not(#companyNo)')
      .prop('checked', false)
      .change()
  })

  /*##################
  UR Plus Signup
  ##################*/

  $('#developer-program-signup-modal').on('submit', '#developer-program-signup-form', function(e) {
    e.preventDefault()
    $('.email-in-use-error').hide()
    if ($('#developer-program-signup-form').valid()) {
      var data = $('#developer-program-signup-form').serializeArray()

      // data.push({
      //   name: 'representingCompany',
      //   value: wordlist
      // })
      //
      // data.push({
      //   name: 'universityAffiliated',
      //   value: wordlist
      // })

      $.ajax({
        url: '/umbraco/surface/user/create',
        type: 'POST',
        data: data,
        success: function(result) {
          closeDialog()
          window.openDialog('#developer-program-signup-success-modal')
        },
        error: function(result) {
          if (result.status === 400) {
            $('.email-in-use-error').show()
          } else {
            closeDialog()
            window.openDialog('#developer-program-signup-failure-modal')
          }
        }
      })
    }
  })

  /*##################
  Ebook
  ##################*/

  $('.ebook')
    .find('form')
    .submit(function() {
      if (!$(this).valid()) {
        return
      }

      var objectifyForm = function(formArray) {
        var returnArray = {}
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value']
        }
        return returnArray
      }

      const data = objectifyForm($(this).serializeArray())

      $.ajax({
        type: 'POST',
        url: 'https://www.universal-robots.com/umbraco/surface/Ebook/SendFile',
        data: data,
        success: function(response) {
          alert('Success!')
          closeDialog()
        }
      }).fail(function(response) {
        alert('Failed to send ebook. Please try again')
      })

      return false
    })

  $('#quote-modal').on('submit', '#quote-form', function(e) {
    e.preventDefault()

    if ($('#quote-form').valid()) {
      var data = $('#quote-form').serialize()

      $.post('/umbraco/surface/urplus/RequestQuote', data, function(result) {
        closeDialog()

        if (result.success) {
          $('#quote-modal-success').fadeIn(200)
        }
      })
    }
  })

  /*##################
  Stepper
  ##################*/

  // from: https://stackoverflow.com/questions/2636512/validate-subset-of-a-form-using-jquery-validate-plugin
  $.validator.prototype.subset = function(container) {
    var ok = true
    var self = this
    $(container)
      .find(':input')
      .each(function() {
        const $this = $(this)
        if (!$this.parent().is(':visible')) return
        if (!self.element($this)) ok = false
      })
    return ok
  }

  const getElement = (element) => {
    if (element.hasClass('formitem') || element.hasClass('formitem-consent')) {
      return element.closest('.grid-row')
    } else if (element.is('input')) {
      return element.closest('.grid-row')
    } else if (element.is('select')) {
      return element.siblings('.chosen-container').closest('.grid-row')
    } else if (element.is('textarea')) {
      return element.closest('.grid-row')
    }
  }

  $(".stepperbox *[name='firstname']").attr('data-step', 1)
  $(".stepperbox *[name='company']").attr('data-step', 1)
  $(".stepperbox *[name='email']").attr('data-step', 1)
  $(".stepperbox *[name='phone']").attr('data-step', 1)

  $(".stepperbox *[name='are_you_already_using_universal_robots_']").attr('data-step', 2)
  $(".stepperbox *[name='current_project_plan']").attr('data-step', 2)
  $(".stepperbox *[name='industries_new']").attr('data-step', 2)
  $(".stepperbox *[name='applications_new']").attr('data-step', 2)
  $(".stepperbox *[name='hs_persona']").attr('data-step', 2)
  $(".stepperbox *[name='message']").attr('data-step', 2)

  $(".stepperbox *[name='country']").attr('data-step', 3)
  $(".stepperbox *[name='state']").attr('data-step', 3)
  $(".stepperbox *[name='address']").attr('data-step', 3)
  $(".stepperbox *[name='city']").attr('data-step', 3)
  $(".stepperbox *[name='purchase_or_demo']").attr('data-step', 3)
  $('.stepperbox .formitem-consent').attr('data-step', 3)

  const $terms = $(".stepperbox *[name='i_agree_to_the_terms']")
  $terms
    .parent()
    .parent()
    .removeClass('grid-shift4')

  const checkbox = $terms.closest('.formitem')
  if (checkbox != null && checkbox.length > 0) {
    checkbox.attr('data-step', 3)
    const label = checkbox.find('strong')
    label.wrap('<div class="grid-text-left"></div>')
    if (label != null && label.length > 0) {
      label.html(
        label
          .html()
          .replace(
            /%.*%/gi,
            ($0, $1) =>
              `<a href="" onclick="window.openDialog('#tt1'); return false;" style="color: white;">${$0.replace(
                /%/gi,
                ''
              )}</a>`
          )
      )
    }
  }

  $('*[data-step="1"]').each(function() {
    getElement($(this))
      .addClass('step-1')
      .addClass('grid-hidden')
      .addClass('grid-mobile-visible')
  })

  $('*[data-step="2"]').each(function() {
    getElement($(this))
      .addClass('step-2')
      .addClass('grid-hidden')
      .addClass('grid-mobile-visible')
  })

  $('*[data-step="3"]').each(function() {
    getElement($(this))
      .addClass('step-3')
      .addClass('grid-hidden')
      .addClass('grid-mobile-visible')
  })

  $('.step-1').removeClass('grid-hidden')
  $('.stepperbox button.button').wrap('<div class="grid-text-left" />')
  $('.stepperbox button.button')
    .addClass('button--dark')
    .removeClass('button--wide')
    .removeClass('button--large')
    .hide()

  $('.next-step').click(() => {
    const $nextStep = $('.stepper-step')
      .not('.stepper-step--active')
      .first()
    const currentStepElementsSelector = `.step-${$nextStep.data('step-hide')}`
    const nextStepElementsSelector = `.step-${$nextStep.data('step-show')}`

    if (
      !$('form')
        .validate()
        .subset(currentStepElementsSelector)
    ) {
      return
    }

    $nextStep.addClass('stepper-step--active')

    if ($nextStep.data('step-hide') === 2) {
      $('.next-step').hide()
      $('.stepperbox button.button').show()
    }

    $(currentStepElementsSelector).fadeOut({
      complete: () => {
        $(nextStepElementsSelector).fadeIn()
      }
    })
  })

  /*##################
  Request quote dynamic dialog
  ##################*/

  $('#currentlyOwning')
    .chosen()
    .change(function(e) {
      var value = $(this).val()
      var element = $('#showIfCurrentlyOwning')

      if (value === 'yes') {
        element.slideDown()
      } else {
        element.slideUp()
      }
    })

  /*##################
  Mobile links
  ##################*/

  $('.mobilemenu-links-pagelink').click(() => {
    $('.mobilemenu--shown').removeClass('mobilemenu--shown')
    $('.header-burger--open').removeClass('header-burger--open')
  })

  /*##################
  Equal height
  ##################*/

  const equalHeightGroup = () => {
    $('.equalheight-group1').matchHeight({
      property: 'min-height'
    })

    $('.equalheight-group2').matchHeight({
      property: 'min-height'
    })

    $('.equalheight-group3').matchHeight({
      property: 'min-height'
    })

    $('.equalheight-group4').matchHeight({
      property: 'height'
    })
  }

  $(equalHeightGroup)
  $(window).resize(equalHeightGroup)

  $(function() {
    $('.equalheight-group2').matchHeight({
      property: 'min-height'
    })
  })

  /*##################
  Javascript links
  ##################*/

  $('[data-href]').on('click', function() {
    window.location = $(this).data('href')
  })

  /*##################
  Smooth scroll to element
  ##################*/

  $('[data-scrollto]').on('click', function() {
    const scrollduration = $(this).data('scrollduration')
    $('html, body').animate(
      {
        scrollTop: $($(this).data('scrollto')).offset().top + parseInt($(this).data('scrolloffset'))
      },
      scrollduration == null ? 1000 : scrollduration
    )
    return false
  })

  /*##################
  Accordion
  ##################*/

  window.initAccordion = function initAccordion() {
    if (isMobileWidth() === true) {
      $('.accordion').accordion({
        header: '.accordion-header',
        collapsible: true,
        active: false,
        icons: false,
        beforeActivate: function(event, ui) {
          // This makes the accordion NOT close when scrolling on mobile
          if (ui.oldHeader.length > 0 && event.handleObj === undefined) {
            return false
          }
        }
      })
    } else {
      const $elem = $('.ui-accordion')
      if ($elem.accordion('instance') != null) {
        $elem.accordion('destroy')
      }
    }
  }

  window.initAccordion()

  $(window).resize(function() {
    window.initAccordion()
  })

  $("a[href*='#']").click(function(e) {
    const modalID = $(this).attr('href')

    if (modalID.length <= 1) {
      return // Skip this one. It's simply href="#"
    }

    const $modal = $(modalID)
    if (
      $modal.length > 0 &&
      !$modal.hasClass('ui-tabs-panel') &&
      ($modal.hasClass('overlay-blocker') || $modal.hasClass('overlay'))
    ) {
      $modal.fadeIn(200)
      $('body').css('overflow', 'hidden')
      return false
    }
  })

  $('table.sortable').tablesort()

  $('.jobs-table-content-original')
    .hide()
    .appendTo('.holder')

  var cloneTable = function() {
    $('.jobs-table-content-original')
      .hide()
      .clone()
      .removeClass('jobs-table-content-original')
      .addClass('jobs-table-content-copy')
      .appendTo('#jobs-table')
  }

  cloneTable()
  $('.jobs-table-content-copy').show()

  $('input#jobs-search').val('')

  $('input#jobs-search').on('keyup paste', function(e) {
    var foundSingleMatch = false
    $('.jobs-table-content-copy').remove()
    cloneTable()

    var value = $(e.target)
      .val()
      .toLowerCase()
    var $tableBody = $('.jobs-table-content-copy')

    $tableBody.find('tr').each(function() {
      var $tr = $(this)
      var foundMatch = false

      $tr.find('td').each(function() {
        if (
          $(this)
            .text()
            .toLowerCase()
            .indexOf(value) !== -1
        ) {
          foundMatch = true
          foundSingleMatch = true
        }
      })

      if (!foundMatch) {
        $tr.remove()
      }
    })

    var tablesort = $('table.sortable').data('tablesort')
    if (tablesort.index > 0) {
      tablesort.sort($('th.sorted'), tablesort.direction)
    }

    if (foundSingleMatch) {
      $('.no-results').hide()
    } else {
      $('.no-results').show()
    }

    $('.jobs-table-content-copy').show()
  })

  /*##################
  Cookiebar
  ##################*/

  const $body = $('body')

  window.initializeCookies = () => {
    // library is not loaded, i.e. should not be used
    if (window.cookieconsent == null) {
      return
    }

    // html is indicateing to not show the cookiebar on this page
    if ($body.data('disable-cookiebar')) {
      return
    }

    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#2a2a2a',
          text: '#ffffff'
        },
        button: {
          background: '#4c96cd',
          text: '#ffffff'
        }
      },
      theme: 'classic',
      revokable: true,
      type: 'info',
      content: {
        message:
          $body.data('cookie-consent-message') ||
          'We use cookies for statistical purposes and enhanced User Experience. You can read more about it ',
        dismiss: $body.data('cookie-consent-accept') || 'ACCEPT',
        deny: $body.data('cookie-consent-deny') || 'DECLINE',
        link: $body.data('cookie-consent-link') || 'IN OUR COOKIE POLICY',
        href: $body.data('cookie-consent-href') || 'https://www.iubenda.com/privacy-policy/75145020/cookie-policy'
      },
      onStatusChange: () => {
        window.location = window.location.href
      },
      compliance: {
        info: `<div class='cc-compliance'>{{dismiss}}</div>`,
        'opt-in': `<div class='cc-compliance cc-highlight'>{{dismiss}}{{allow}}</div>`,
        'opt-out': `<div class='cc-compliance cc-highlight'>{{dismiss}}{{deny}}</div>`
      },
      revokeBtn: `<div class='cc-revoke {{classes}}'>${$body.data('cookie-consent-policy') || 'Cookie Policy'}</div>`
    })

    // $('.cc-dismiss').click(() => {
    //   window.urTrackEvent('Cookie', 'Accept', $('body').attr('lang'))
    // })

    // $('.cc-deny').click(() => {
    //   window.urTrackEvent('Cookie', 'Decline', $('body').attr('lang'))
    // })
  }

  window.addEventListener('load', function() {
    window.initializeCookies()
  })
})
