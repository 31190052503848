const $ = require('jquery')
// require('../../bower_components/magnific-popup/dist/jquery.magnific-popup.js')

const initVideoToggle = () => {
  const $panelVideo = $('.panel-video')
  const $videoIframe = $panelVideo.find('iframe')

  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      $videoIframe.attr('src', '')
      $panelVideo.hide()
    }
  })

  $('.panel-video-play a, .panel-video-close').click(function () {
    const videoIsLoaded = $videoIframe.attr('src') !== ''

    if (!videoIsLoaded) {
      $videoIframe.attr('src', $videoIframe.data('src') + '&autoPlay=1')
    }

    $panelVideo.fadeToggle(1000, function () {
      if (videoIsLoaded) {
        $videoIframe.attr('src', '')
      }
    })

    return false
  })
}

module.exports = {
  init: () => {
    $(() => {
      initVideoToggle()

      const settings = {
        type: 'inline',
        removalDelay: 200,
        mainClass: 'panel-wrapper',
        callbacks: {
          open: function () {
            $('.panel .panel-col').matchHeight()
            $('.panel').each(function () {
              const panel = $(this)
              const panelSlideCount = panel.find('.panel-slide').length
              const panelImage = panel.find('.panel-image')
              const panelImageSlideCount = panelImage.find('.panel-image-slide').length
              panel.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: panelSlideCount > 1,
                slide: '.panel-slide',
                infinite: false,
                fade: true
              })
              panelImage.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: panelImageSlideCount > 1,
                arrows: false,
                slide: '.panel-image-slide',
                infinite: true,
                autoplay: true,
                autoplaySpeed: 5000
              })
            })
          },
          close: function () {
            $('.panel').slick('unslick')
            $('.panel-image').slick('unslick')
            $('body').css('overflow', '')
          }
        }
      }

      $("a[href*='#']").each(function (i, e) {
        const $clickedElement = $(this)
        const href = $clickedElement.attr('href')

        if (href.length > 1) {
          try {
            const $target = $(href)
            if ($target.hasClass('panel')) {
              $clickedElement.addClass('panel-trigger')
            }
          } catch (error) {
            // ignored (to avoid errors when href contains invalid characters for jquery lookup)
          }
        }
      })

      $('.panel-trigger').magnificPopup(settings)

      $('.panel').on('beforeChange init', function (event, slick, currentSlide, nextSlide) {
        if (slick.$slider.hasClass('panel')) {
          $('.panel .panel-col').matchHeight()
          const i = (nextSlide ? nextSlide : 0) + 1
          $('.panel-counter').text(i + ' of ' + slick.slideCount)
        }
      })
      $('.panel').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        if (slick.$slider.hasClass('panel')) {
          $(this).find('.panel-image').slick('slickGoTo', 0, true)
        }
      })
    })
  }
}
