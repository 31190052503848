const $ = require('jquery')
// require('../../bower_components/magnific-popup/dist/jquery.magnific-popup.js')

module.exports = {
  init: () => {
    $(() => {
      $('.popup-trigger').magnificPopup({
        type: 'inline',
        removalDelay: 200,
        alignTop: true,
        mainClass: 'popup-wrapper',
        callbacks: {
          close: function () {
            $('body').css('overflow', '')
          }
        }
      })
    })
  }
}
