if (window.jQuery == null) {
  window.$ = require('jquery')
  window.jQuery = window.$
}

const oldHeight = -1

const onResize = function () {
  const footer = $('footer.footer, footer.campaignfooter')
  const footerHeight = footer.innerHeight()
  if (oldHeight === footerHeight) {
    return
  } // skip if the height has not changed since last time

  $('.main.sticky-footer-page-content-wrapper').css({ marginBottom: +(footerHeight * -1) + 'px' })
  $('#sticky-footer-styling').remove()
  $(
    "<style id='sticky-footer-styling'>.main.sticky-footer-page-content-wrapper::after{height:" +
      footerHeight +
      'px !important} </style>'
  ).appendTo('head')
}

module.exports = {
  init: () => {
    $(() => {
      onResize()
      $(window).resize(onResize)
    })
  },

  onResize: onResize
}
