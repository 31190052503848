var burgerMenu = $('.header-burger')

burgerMenu.click(function (event) {
  var mobilemenu = $('.mobilemenu')
  var burger = $('.header-burger')

  if (mobilemenu.hasClass('mobilemenu--shown')) {
    //$('body').css({overflow: 'visible'})
  } else {
    //$('body').css({overflow: 'hidden'})
  }

  burger.toggleClass('header-burger--open')
  mobilemenu.toggleClass('mobilemenu--shown')
})

var mobileMenuTimeout

$('.mobilemenu-links--trigger').click(function (event) {
  $('.mobilemenu-links--submenu').css('display', 'none')
  clearTimeout(mobileMenuTimeout)
  $('#' + $(this).data('target')).css('display', 'inline-block')
  $('.mobilemenu-links-container').toggleClass('mobilemenu-links-container--opensubmenu')
  return false
})
$('.mobilemenu-links--close').click(function (event) {
  $('.mobilemenu-links-container').removeClass('mobilemenu-links-container--opensubmenu')
  mobileMenuTimeout = setTimeout(function () {
    $('.mobilemenu-links--submenu').css('display', 'none')
  }, 300)

  return false
})
