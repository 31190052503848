const $ = window.jQuery

$(() => {
  function setCourseTracksWrapperSize() {
    var rowWrapper = $('#course-tracks-wrapper')
    if (rowWrapper.length > 0) {
      var rowWrapperWidth = $('#course-tracks-wrapper').width()
      $('.module-dropdown').each(function () {
        var element = $(this)
        element.width(rowWrapperWidth)
      })
    }
  }
  function updateMargin() {
    $('.module--expanded').each(function () {
      var wrapper = $(this).closest('.module-wrapper')
      var expander = wrapper.find('.module-dropdown')
      wrapper.css({ marginBottom: expander.outerHeight() + 20 })
    })
  }

  $('.module').click(function () {
    var element = $(this)
    var expandedElement = element.parent().find('.module-dropdown')
    var isVisible = expandedElement.is(':visible')

    if (isMobileWidth() === false) {
      closeAll()
    }

    if (isVisible === false) {
      element.closest('.module-wrapper').css({ marginBottom: expandedElement.outerHeight() + 20 })
      $(this).addClass('module--expanded')
      expandedElement.slideDown(200, 'linear')
    }
  })
  $('.module-dropdown-close').click(function () {
    var moduleWrapper = $(this).closest('.module-wrapper')
    var module = moduleWrapper.find('.module')
    var moduleDropdown = moduleWrapper.find('.module-dropdown')
    module.removeClass('module--expanded')
    moduleDropdown.slideUp(200, 'linear')
    moduleWrapper.css('margin-bottom', '0')

    return false
  })

  setCourseTracksWrapperSize()

  $(window).resize(function () {
    setCourseTracksWrapperSize()
    updateMargin()
    if ($('.module--expanded').length > 1) {
      closeAll()
    }
  })

  $('.module').matchHeight()

  $('.module-dropdown').each(function () {
    $(this).find('.module-dropdown-header').matchHeight()
  })

  function closeAll() {
    $('.module').removeClass('module--expanded')
    $('.module-dropdown').slideUp(200, 'linear')
    $('.module').each(function () {
      $(this).closest('.module-wrapper').css({ marginBottom: '' })
    })
  }

  function isMobileWidth() {
    return $('.mediaquery--mobile').is(':visible')
  }
})
