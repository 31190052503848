const event = navigator.userAgent.match(/iPad/i) ? 'touchstart' : 'click'

const initialize = () => {
  const $login = $('.login')
  const $loginLink = $('.login-link')

  $('html, body').on(event, function (e) {
    if ($(e.target).closest('.login-box').length === 0) {
      $login.removeClass('login--active')
      $('.d-header-icons').fadeIn()
    }
  })

  $loginLink.click(function () {
    $login.toggleClass('login--active')
    $('.d-header-icons').toggle()

    // close mobilemenu
    $('.mobilemenu--shown').removeClass('mobilemenu--shown')
    $('.header-burger--open').removeClass('header-burger--open')
    return false
  })
}

initialize()
