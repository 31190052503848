const $ = window.jQuery

$(() => {
  // window.initAccordion = function initAccordion() {
  //   $(".filtermenu-dropdown").accordion({
  //     header: ".filtermenu-dropdown-title",
  //     collapsible: true,
  //     active: false,
  //     icons: false,
  //     heightStyle: "content"
  //   });
  // };
  // window.initAccordion();
  // $(window).resize(function() {
  //   window.initAccordion();
  // });
  // $(".filtermenu").each(function(index, item) {
  //   $filter = $(item);
  //   $trigger = $filter.find(".filtermenu-trigger");
  //   $trigger.click(function() {
  //     $filter.toggleClass("filtermenu--active");
  //   });
  // });
  // var ua = navigator.userAgent,
  //   event = ua.match(/iPad/i) ? "touchstart" : "click";
  // $("html, body").on(event, function(e) {
  //   if ($(e.target).closest(".filtermenu").length == 0) {
  //     $(".filtermenu").removeClass("filtermenu--active");
  //   }
  // });
})
