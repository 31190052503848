// im so sorry, but this file needs to be named x (it uses window.openDialog, which is not assigned unless this file is included after functions.js)

const $ = window.jQuery

$(() => {
  const openDialogForHash = () => {
    try {
      if (window.location.hash == null || window.location.hash === '') {
        return
      }

      const $element = $(window.location.hash)

      if ($element.length > 0) {
        window.openDialog($element)
      }
    } catch (err) {
      // ignored
    }
  }

  openDialogForHash()

  $(window).on('hashchange', function () {
    openDialogForHash()
  })

  $('.choicebutton').on('click', function (event) {
    const $target = $(event.target)
    if ($target.hasClass('choicebutton')) {
      window.openDialog($(this).next('.overlay-blocker'))
    }
  })
})
