const $ = window.jQuery

$(() => {
  $('.fancyslider').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var slideNumber = (currentSlide ? currentSlide : 0) + 1
    $('.fancyslider-arrows-text').text(slideNumber + '/' + slick.slideCount)
  })

  $('.fancyslider').slick({
    dots: false,
    arrows: true,
    slide: '.fancyslider-slide',
    appendArrows: '.fancyslider-arrows',
    prevArrow: '<div class="slick-prev">Previous</div>',
    nextArrow: '<div class="slick-next">Next</div>'
  })
})
