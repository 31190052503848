const $ = window.jQuery
const clipboard = require('clipboard-polyfill')

$(() => {
  $.fn.hasVerticalScrollBar = function () {
    return this.get(0).scrollHeight > this.get(0).clientHeight
  }

  $.fn.hasHorizontalScrollBar = function () {
    return this.get(0).scrollWidth > this.get(0).clientWidth
  }

  const $expandControl = $('.codeelement-content-expandcontrol')
  const $copyToClipboardButton = $('.codeelement-copy')
  const $codeElements = $('.codeelement')

  const initializeCodeElements = () => {
    $codeElements.each((index, codeElement) => {
      const $codeElement = $(codeElement)
      const $code = $codeElement.find('code')
      const $expandControl = $codeElement.find('.codeelement-content-expandcontrol')

      $code.hasVerticalScrollBar() ? $expandControl.removeClass('grid-hidden') : $expandControl.addClass('grid-hidden')

      $code.hasHorizontalScrollBar()
        ? $expandControl.addClass('codeelement-content-expandcontrol--raised')
        : $expandControl.removeClass('codeelement-content-expandcontrol--raised')
    })
  }

  initializeCodeElements()
  $(window).on('resize', initializeCodeElements)

  $expandControl.on('click', function () {
    $(this).closest('.codeelement').addClass('codeelement--expanded')
    return false
  })

  $copyToClipboardButton.on('click', function () {
    const text = $(this).next('textarea').text()

    const dt = new clipboard.DT()
    dt.setData('text/plain', text)
    dt.setData('text/html', text)
    clipboard.write(dt)

    return false
  })
})
