const $ = window.jQuery

$(() => {
  const $headerToolsItemLanguage = $('.header-tools-item--language, .language-trigger')
  const headerToolsItemLanguageActiveClass = 'header-tools-item--active'
  const $languagePicker = $('.languagepicker')
  const languagePickerActiveClass = 'languagepicker--active'

  const closeLanguagePicker = () => {
    if ($languagePicker.hasClass(languagePickerActiveClass)) {
      $languagePicker.removeClass(languagePickerActiveClass)
      $headerToolsItemLanguage.removeClass(headerToolsItemLanguageActiveClass)
      $languagePicker.css('overflow', 'hidden')
      return true
    }
    return false
  }

  window.closeLanguagePicker = closeLanguagePicker

  $(window).scroll(function () {
    closeLanguagePicker()
  })

  $headerToolsItemLanguage.click(() => {
    if (closeLanguagePicker()) return false

    window.clearHeaderTools()
    const animationTime = window.pageYOffset / 2
    const animationTimeLimited = animationTime >= 450 ? 450 : animationTime
    $('html, body')
      .stop()
      .animate({ scrollTop: 0 }, animationTimeLimited)
      .promise()
      .then(() => {
        $languagePicker.css('overflow', 'hidden')
        setTimeout(() => {
          $languagePicker.toggleClass(languagePickerActiveClass)
          $headerToolsItemLanguage.toggleClass(headerToolsItemLanguageActiveClass)

          setTimeout(() => {
            $languagePicker.css('overflow', 'visible')
          }, 200) // animation delay defined in scss
        }, 20) // to counter window scroll
      })

    return false
  })

  $('.languagepicker-action').click(() => {
    closeLanguagePicker()
    return false
  })
})
