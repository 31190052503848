const $ = window.jQuery

$(() => {
  let counter = 0
  $('.compare-item').click(function (event) {
    const compareOptionElement = $(this)
    if (compareOptionElement.hasClass('compare-item--selected')) {
      compareOptionElement.removeClass('compare-item--selected')
    } else {
      counter++
      compareOptionElement.data('index', counter)
      compareOptionElement.addClass('compare-item--selected')

      removeExcessSelected()
    }
    updateTable()
    return false
  })

  updateTable()

  function updateTable() {
    if ($('.compare-item--selected').length >= 1) {
      $('.compare-result').addClass('compare-result--active')
    } else {
      $('.compare-result').removeClass('compare-result--active')
    }
    $('[class*="compare-table-col--"]').hide()
    $('.compare-item--selected').each(function (index, value) {
      const compareOptionElement = $(this)
      const selectedRobot = compareOptionElement.data('robot')
      if (compareOptionElement.data('index') === undefined) {
        counter++
        compareOptionElement.data('index', counter)
      }
      const selectedRobotClass = '.compare-table-col--' + selectedRobot
      $(selectedRobotClass).show()
    })
  }
  function isTabletWidth() {
    return $('.mediaquery--tablet').is(':visible')
  }

  function removeExcessSelected() {
    if (isTabletWidth()) {
      const $selected = $('.compare-item--selected')
      if ($selected.length >= 2) {
        const indices = $selected.map(function (idx, selected) {
          return parseInt($(selected).data('index'))
        })
        const lowIndices = indices
          .sort(function (a, b) {
            return a - b
          })
          .slice(0, indices.length - 2)
        lowIndices.each((idx, index) => {
          $('.compare-item--selected[data-index=' + index + ']').removeClass('compare-item--selected')
        })
      }
    }
  }

  $(window).on('resize', function () {
    removeExcessSelected()
    updateTable()
  })
})
