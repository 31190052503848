const $ = window.jQuery

$(() => {
  $('.flowslider-wrapper').each(function (i, obj) {
    const $flowslider = $(obj).find('.flowslider')
    const $flowsliderNav = $(obj).find('.flowslider-nav')
    const hasArrows = $flowslider.find('.flowslider-control').length > 0

    $flowslider.slick({
      dots: false,
      arrows: hasArrows,
      slide: '.flowslider-item',
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0',
      variableWidth: false,
      asNavFor: '.flowslider-nav',
      swipe: false,
      speed: 0,
      accessibility: false,
      nextArrow: $flowslider.find('.flowslider-control--next'),
      prevArrow: $flowslider.find('.flowslider-control--prev')
    })

    $flowsliderNav.slick({
      dots: true,
      arrows: false,
      slide: '.flowslider-nav-item',
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      asNavFor: '.flowslider',
      fade: true,
      speed: 400,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            fade: false
          }
        }
      ]
    })

    $flowsliderNav.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $flowslider.find('.flowslider-control').css('pointer-events', 'none')
    })
    $flowsliderNav.on('afterChange', function (event, slick, currentSlide, nextSlide) {
      $flowslider.find('.flowslider-control').css('pointer-events', 'all')
    })
  })
})
