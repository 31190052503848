const $ = window.jQuery

$(() => {
  if ($('.greeter-video').length > 0) {
    let viewportTop = 0
    let viewportTopPrevious = 0
    let played = false
    $(window).on('load resize scroll', function () {
      $('.greeter-video').each(function (index, e) {
        viewportTopPrevious = viewportTop
        viewportTop = $(window).scrollTop()
        const viewportBottom = viewportTop + $(window).height()
        const distanceTop = $(e).offset().top
        const inView = Math.max(viewportBottom - distanceTop, 0)
        const inViewPart = Math.min(inView / ($(window).height() * 0.6), 1)

        if (inViewPart >= 0.5 && viewportTopPrevious < viewportTop && !played) {
          $(e)[0].play()
          played = true
        } else if (inViewPart < 0.1) {
          played = false
        }
      })
    })
  }
})
