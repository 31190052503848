const Cookies = require('js-cookie')

const dismissedCookieID = 'languageSuggestionDismissed'

const offsetElements = () => {
  const $languageSuggestion = $('.languagesuggestion')
  const $header = $('header.header')
  const $main = $('div.main')

  const languageSuggestionOffset = Math.abs(parseFloat($languageSuggestion.css('top')) || 0)
  const languageSuggestionHeight = $languageSuggestion.outerHeight()
  const distanceToPushContent = Math.max(0, languageSuggestionHeight - languageSuggestionOffset)

  $header.css({
    marginTop: distanceToPushContent
  })

  $main.css({
    paddingTop: distanceToPushContent
  })
}

const hideOnAnchorClicked = () => {
  const $languageSuggestion = $('.languagesuggestion')
  const anchors = $languageSuggestion.find('a')

  anchors.on('click', (event) => {
    const $target = $(event.target)
    $languageSuggestion.animate(
      {
        top: $languageSuggestion.outerHeight() * -1
      },
      {
        step: offsetElements,
        complete: offsetElements
      }
    )

    if (!$target.data('accepted')) {
      Cookies.set(dismissedCookieID, true)
      return false // ignore the click event
    } else {
      Cookies.set(dismissedCookieID, false)
      // let the click event pass through (so that the user is redirected)
    }
  })
}

const showLanguageSuggestion = () => {
  const $languageSuggestion = $('.languagesuggestion')

  $languageSuggestion.css({
    top: $languageSuggestion.outerHeight() * -1
  })

  $languageSuggestion.animate(
    {
      top: 0
    },
    {
      step: offsetElements,
      complete: offsetElements
    }
  )
}

module.exports = {
  dismissedCookieID: dismissedCookieID,

  init: () => {
    $(window).on('load', () => {
      // we use load here to ensure that all assets are loaded before running this script
      const $languageSuggestion = $('.languagesuggestion')
      const $language = $languageSuggestion.find('.languagesuggestion-language')
      const $accept = $languageSuggestion.find('.languagesuggestion-accept')
      const originCountryCode = $languageSuggestion.data('origin-country')

      const $alternativeFromRegion = $(
        `link[rel='alternate'][href!=''][data-current-language='false'][data-regions*='${originCountryCode}']`
      )
      if ($alternativeFromRegion.length === 0) return // do nothing if the page has no alternative version

      $language.text($alternativeFromRegion.data('language') || originCountryCode)
      $accept.attr('href', $alternativeFromRegion.attr('href'))

      const hasDismissed = Cookies.get(dismissedCookieID) || false
      if (!hasDismissed) {
        setTimeout(() => {
          offsetElements()
          hideOnAnchorClicked()
          showLanguageSuggestion()
          $(window).resize(offsetElements)
          $(window).scroll(offsetElements)
        }, 1000)
      }
    })
  }
}

// $("link[rel='alternate'][href!=''][hrefland!='']");
