$(() => {
  $('.productslider-wrapper').each(function (i, obj) {
    const $wrapper = $(obj)
    const $productslider = $wrapper.find('.productslider')
    const $productslidernav = $wrapper.find('.productslider-nav')
    const $slideCount = $productslidernav.find('.productslider-nav-slide').length
    const tabs = $('.tabs').first()
    const tabCount = tabs.find('> ul > li').length

    if ($slideCount <= 6) {
      $productslidernav.addClass('productslider-nav--fewslides')
    }

    $productslider.attr('data-slider-index', i)
    $productslidernav.attr('data-slider-index', i)

    $productslider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      slide: '.productslider-slide',
      asNavFor: `.productslider-nav[data-slider-index="${i}"]`
    })

    $productslidernav.slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      asNavFor: `.productslider[data-slider-index="${i}"]`,
      focusOnSelect: true,
      vertical: true,
      verticalSwiping: true,
      prevArrow: "<i class='fa fa-angle-up productslider-nav-prev' aria-hidden='true'></i>",
      nextArrow: "<i class='fa fa-angle-down productslider-nav-next' aria-hidden='true'></i>"
    })

    if ($slideCount <= 6) {
      setInterval(() => {
        $productslidernav.find('.slick-track').css('transform', 'none')
      }, 300)
      $productslidernav.on('afterChange', function (event, slick, currentSlide) {
        $productslidernav.find('.slick-track').css('transform', 'none')
      })
    }

    let productSliderTextLimitHeight = true
    $productslider.on('setPosition', function () {
      var targetHeight = $(this).height()
      var slideHeight = (targetHeight - 36) / 6
      $productslidernav.find('.slick-slide').css('height', slideHeight + 'px')

      if (isTabletWidth() || productSliderTextLimitHeight === false) {
        $wrapper.find('.productslider-text div').css('max-height', '')
      } else {
        $wrapper.find('.productslider-text div').css('max-height', $(this).height() - 65)
      }
    })

    $('.productslider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $wrapper.find('iframe').attr('src', function (i, val) {
        return val
      })
    })

    $wrapper.find('.productslider-text div').dotdotdot({
      callback: function (isTruncated) {
        const $element = $(this)
        const $textlink = $wrapper.find('.productslider-textlink')
        $textlink.css('display', isTruncated ? '' : 'none')
        $textlink.click(function () {
          $element.dotdotdot('unwatch')
          $element.dotdotdot('destroy')
          productSliderTextLimitHeight = false
          setTimeout(() => {
            $('.productslider-textlink').remove()
          }, 100)
          return false
        })
      },
      height: 'watch',
      watch: true,
      ellipsis: '...',
      wrap: 'letter'
    })

    $(window).on('resize', () => $productslider.slick('setPosition'))
  })

  function isTabletWidth() {
    return $('.mediaquery--tablet').is(':visible')
  }
})
