const $ = window.jQuery

$(() => {
  //return
  const distanceToNextElement = 40
  let elementPositions = []
  let elementHeights = []

  const pad = (str, max) => {
    str = str.toString()
    return str.length < max ? pad('0' + str, max) : str
  }

  const updateInitialElementPositions = () => {
    elementPositions = []
    elementHeights = []
    $('.verticalstepper-wrapper').each((i, e) => {
      const $element = $(e)
      const steps = Number($element.data('verticalstepper-steps'))
      const $verticalStepper = $element.find('.verticalstepper')
      const verticalStepperHeight = $verticalStepper.outerHeight()
      $verticalStepper.css({
        height: verticalStepperHeight + 'px'
      })

      $element.css({
        height: verticalStepperHeight * (steps + 1) + distanceToNextElement + 'px'
      })

      elementPositions.push($element.offset().top)
      elementHeights.push(verticalStepperHeight)
    })
  }

  updateInitialElementPositions()
  setTimeout(updateInitialElementPositions, 1)

  let initialOrientation = $(window).outerWidth() > $(window).outerHeight()

  $(window).on('resize', () => {
    if (initialOrientation !== $(window).outerWidth() > $(window).outerHeight()) {
      initialOrientation = !initialOrientation
      updateInitialElementPositions()
    }
  })

  $(window).on('scroll', () => {
    const windowPosition = $(window).scrollTop()
    $('.verticalstepper-wrapper').each((i, e) => {
      const $element = $(e)
      const elementPosition = elementPositions[i]
      const elementHeight = elementHeights[i]
      const difference = windowPosition - elementPosition

      // calculate which step we are on
      const $stepper = $element.find('.verticalstepper')
      const percentage = difference / elementHeight
      const currentStep = 1 + Math.floor(percentage)
      const currentStepRounded = Math.max(1, Math.min($element.data('verticalstepper-steps'), currentStep))

      // calculate the percentage of the current step (if we are on the last step, only fill the progress bar)
      const percentageWidth = currentStep - 1 >= $element.data('verticalstepper-steps') ? 100 : (percentage % 1) * 100
      $element.find('.verticalstepper-bar-progress').css({
        width: percentageWidth + '%'
      })

      // check if we have change step since last tick
      const currentElementStep = $element.data('verticalstepper-step')
      if (currentElementStep !== currentStepRounded) {
        $element.data('verticalstepper-step', currentStepRounded)

        const previousStep = $(`.step${currentElementStep}`)
        const nextStep = $(`.step${currentStepRounded}`)

        previousStep.addClass(currentElementStep < currentStepRounded ? 'fadeout--in' : 'fadeout--out')
        nextStep.removeClass('fadeout--out fadeout--in')
      }

      // check and set the position of the slider-element depending on scroll
      const heightOffset = $element.outerHeight() - elementHeight
      const hasScrolledFarEnough = windowPosition >= elementPosition
      const hasScrolledTooFar = windowPosition >= elementPosition + heightOffset - distanceToNextElement

      $stepper.css({
        position: hasScrolledFarEnough && !hasScrolledTooFar ? 'fixed' : hasScrolledTooFar ? 'absolute' : 'static',

        top: hasScrolledTooFar ? heightOffset - distanceToNextElement + 'px' : '0px'
      })
    })
  })

  /*$(function() {
    let _currentStep = 1  // Current selected step. This changes as page is flipped.
    let _totalSteps = 5  // Total amount of steps.

    updateStepper(_currentStep, _totalSteps)

    function updateStepper(currentStep, totalSteps) {
      let progress = currentStep / totalSteps * 100
      $('.verticalstepper-counter-current').text( pad(currentStep, 2) );
      $('.verticalstepper-counter-total').text( pad(_totalSteps, 2) );
      $('.verticalstepper-bar-progress').css('width', progress + '%');
    }

    function pad (str, max) {
      str = str.toString();
      return str.length < max ? pad("0" + str, max) : str;
    }

  });*/
})
