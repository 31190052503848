const $ = window.jQuery

$(() => {
  const mouseIn = function (element) {
    if (element.type) {
      element = $(this)
    }

    const primary = element.find('.substitute-item--primary')
    const secondary = element.find('.substitute-item--secondary')

    secondary.stop()
    primary.stop().fadeOut(150, 'swing', () => {
      secondary.fadeIn(150).css('display', 'inline-block')
    })
  }

  const mouseOut = function (element) {
    if (element.type) {
      element = $(this)
    }

    const primary = element.find('.substitute-item--primary')
    const secondary = element.find('.substitute-item--secondary')

    primary.stop()
    secondary.stop().fadeOut(150, 'swing', () => {
      primary.fadeIn(150).css('display', 'inline-block')
    })
  }

  $('.substitute').hover(mouseIn, mouseOut)

  var eventType = navigator.userAgent.match(/iPad/i) ? 'touchstart' : 'click'
  $('html, body').on(eventType, function (e) {
    if ($(e.target).parent().closest('.substitute').length !== 0) {
      mouseOut($(e.target).parent())
    }
  })
})
