const $ = window.jQuery

$(() => {
  var formenablerArr = $('.formenabler')
  formenablerArr.find('.formenabler-content input').prop('disabled', true)

  formenablerArr.each(function (index) {
    var formenabler = $(this)
    var form = formenabler.closest('form')

    form.change(function () {
      var triggerArr = formenabler.find('.formenabler-trigger')
      var triggerSet = false
      triggerArr.each(function (index) {
        if ($(this).prop('checked') === true) {
          triggerSet = true
        }
      })
      if (triggerSet) {
        formenabler.addClass('formenabler--active')
        formenabler.find('.formenabler-content input').prop('disabled', false)
      } else {
        formenabler.removeClass('formenabler--active')
        formenabler.find('.formenabler-content input').prop('disabled', true)
      }
    })
  })
})
