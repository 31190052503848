const $ = window.jQuery

$(() => {
  $(function () {
    $('.verticalseperator-line').matchHeight({
      property: 'height',
      target: $('.verticalseperator--matchheight')
    })
  })
})
