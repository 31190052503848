const $ = window.jQuery

window.clearHeaderTools = () => {
  $('.header-tools-item--search.header-tools-item--active').removeClass('header-tools-item--active')
  $('.header-overlay--active').removeClass('header-overlay--active')
}

$(() => {
  $('.header-tools-item--search').click(function () {
    if ($(this).hasClass('header-tools-item--active')) {
      window.clearHeaderTools()
      return
    }

    window.closeLanguagePicker()

    $(this).addClass('header-tools-item--active')
    $('.header-overlay--search').addClass('header-overlay--active')
    $('.searchbar input').focus()
    return false
  })

  const ua = navigator.userAgent
  const event = ua.match(/iPad/i) ? 'touchstart' : 'click'
  $('html, body').on(event, function (e) {
    if ($(e.target).closest('.header-overlay--search .header-overlay-content').length === 0) {
      window.clearHeaderTools()
    }
  })
})
