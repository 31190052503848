const $ = window.jQuery

$(() => {
  $("a[href*='#']").on('click', function (e) {
    const clickedElement = $(this)
    const href = clickedElement.attr('href')

    if (href.length > 1) {
      const $target = $(href)

      if ($target.hasClass('anchor')) {
        e.preventDefault()
        const $header = $('.header')
        const headerNoScrollAnimationClass = 'header--noscrollanimation'
        $header.addClass(headerNoScrollAnimationClass)
        const animationDuration = Math.min(500, Math.abs($(document).scrollTop() - $target.offset().top))
        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top
            },
            animationDuration,
            () => {
              setTimeout(() => clickedElement.trigger('anchorScrollFinished'), 5)
            }
          )
      }
    }
  })
})
