import $ from 'jquery'

let amountOfSlides = 0
let currentSlideIndex = 0

const updateInterval = 50
const slideChangeTime = window.heroslideshowDelay || 10 * 1000
let currentProgress = 0

const strokeRadius = 6.5
const strokeOffset = Math.round(2 * Math.PI * Math.round(strokeRadius))

// only 1 hero slideshow can be active on a site at a time
const doInterval = () => {
  const currentDot = $(`.heroslideshow svg[data-heroslideshow-index="${currentSlideIndex}"]`)
  const percentage = currentProgress / slideChangeTime
  const newProgress = currentProgress + updateInterval

  if (percentage >= 1) {
    currentProgress = 0

    currentDot.css({
      'stroke-dashoffset': strokeOffset
    })

    const nextSlideIndex = (currentSlideIndex + 1) % amountOfSlides
    currentSlideIndex = nextSlideIndex

    $('.heroslideshow').slick('slickGoTo', currentSlideIndex, false)
  } else {
    currentProgress = newProgress

    // this is what updates the svg progress visually
    // we need to normalize this, to a scale of strokeOffset - 0
    // where 0 is full, while strokeOffset is empty
    currentDot.css({
      'stroke-dashoffset': Math.min(strokeOffset, strokeOffset - Math.ceil(percentage * strokeOffset * 100) / 100)
    })
  }
}

let currentInterval

const startInterval = () => {
  currentInterval = setInterval(doInterval, updateInterval)
  return currentInterval
}

const stopInterval = () => {
  if (currentInterval == null) {
    return // already stopped
  }

  clearInterval(currentInterval)
  currentInterval = null

  // reset the css for the current dot and current progress
  const currentDot = $(`.heroslideshow svg[data-heroslideshow-index="${currentSlideIndex}"]`)
  currentProgress = 0
  currentDot.css({
    'stroke-dashoffset': strokeOffset
  })
}

export const init = () => {
  $(() => {
    const heroSlideshow = $('.heroslideshow')

    heroSlideshow.on('mousedown touchdown', () => {
      stopInterval()

      // start the slideshow again in a bit
      setTimeout(() => {
        stopInterval() // just in case
        startInterval()
      }, 4 * slideChangeTime)
    })

    heroSlideshow
      .on('init', (_, slick) => {
        amountOfSlides = slick.slideCount
        heroSlideshow.css({ opacity: 1 })

        if (amountOfSlides > 1) {
          startInterval()
        }
      })
      .on('beforeChange', (_, __, currentSlide, nextSlide) => {
        const currentDot = $(`.heroslideshow svg[data-heroslideshow-index="${currentSlide}"]`)

        // reset the css for the current dot
        currentDot.css({
          'stroke-dashoffset': strokeOffset
        })

        // update the index and progress
        currentSlideIndex = nextSlide
        currentProgress = 0
      })

    heroSlideshow.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      dots: true,
      customPaging: (_, i) => `<svg height="24" width="24" data-heroslideshow-index="${i}">
                                      <circle cx="12" cy="12" r="5" fill="white" />
                                      <circle cx="12" cy="12" r="${strokeRadius}" stroke="#529fd5" stroke-width="3" fill="white" fill-opacity="0" />
                                    </svg>`,
      autoplay: false
    })
  })
}
