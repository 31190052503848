const $ = window.jQuery

$(() => {
  const $profileImage = $('#profileImage')

  $('#profileImageLink').click(() => {
    $profileImage.click()
    return false
  })

  $profileImage.change(() => {
    const fileName = $(this)
      .val()
      .replace(/C:\\fakepath\\/i, '') // https://stackoverflow.com/questions/6365858/use-jquery-to-get-the-file-inputs-selected-filename-without-the-path

    if (!fileName.match(/^.*\.(?:jpe?g|gif|png)$/i)) {
      alert('Please select a valid file')
      $(this).val = ''
      return false
    }

    $('.filename').html(`<br/><code class="filename" style="margin-top: 10px !important;">${fileName}</code>`)
  })
})
