const $ = window.jQuery

$(() => {
  const $pageMenuWrapper = $('.pagemenu-wrapper')
  const $pageMenuDummy = $('.pagemenu-dummy')

  if ($pageMenuWrapper.length === 0 || $pageMenuDummy.length === 0) {
    return
  }

  let pageMenuWrapperOffset = 0
  const $window = $(window)

  const resetHeight = () => {
    const targetHeight = $pageMenuWrapper.height()
    $pageMenuDummy.height(targetHeight)
  }

  const updatePageMenuWrapperOffset = () => {
    pageMenuWrapperOffset = $pageMenuDummy.offset().top
  }

  resetHeight()
  updatePageMenuWrapperOffset()

  const stickyClass = 'pagemenu-wrapper--sticky'
  const scrollHandler = () => {
    updatePageMenuWrapperOffset()
    $window.scrollTop() >
    pageMenuWrapperOffset - (20 + ($('.mediaquery--burger').is(':visible') ? $('.header').outerHeight() : 0))
      ? $pageMenuWrapper.addClass(stickyClass)
      : $pageMenuWrapper.removeClass(stickyClass)
  }

  $window.on('scroll', scrollHandler)

  $window.on('resize', function () {
    updatePageMenuWrapperOffset()
    scrollHandler()
    resetHeight()

    setTimeout(() => {
      updatePageMenuWrapperOffset()
      scrollHandler()
      resetHeight()
    }, 100)
  })

  const $pagemenuAnchor = $('.pagemenu a')

  $pagemenuAnchor.matchHeight({
    property: 'height'
  })

  const lockClass = 'pagemenu--active--lock'
  const activeClass = 'pagemenu--active'

  $pagemenuAnchor.on('click', function () {
    $('.' + activeClass).removeClass(activeClass)
    $('.' + lockClass).removeClass(lockClass)

    $(this).addClass(lockClass)
    $(this).addClass(activeClass)
  })

  $pagemenuAnchor.on('anchorScrollFinished', function () {
    $(this).removeClass(lockClass)
  })

  $(window).on('scroll', () => {
    $('.' + activeClass)
      .not('.' + lockClass)
      .removeClass(activeClass)
  })
})
