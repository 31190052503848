import { init as heroslideshowInit } from './_heroslideshow'
import { initializeVideoPopup } from './_videopopup'

window.$ = require('jquery')
window.jQuery = window.$

import { ElementQueries } from 'css-element-queries'
window.ElementQueries = ElementQueries

require('@bower_components/jquery-validation')
require('../../bower_components/slick-carousel/slick/slick.min.js')
require('../../bower_components/chosen/chosen.jquery.js')
require('../../bower_components/jquery-ui/jquery-ui.js')
require('../../bower_components/jquery-tablesort/jquery.tablesort.min.js')
require('../../bower_components/jqueryui-touch-punch/jquery.ui.touch-punch.min.js')
require('../../bower_components/trunk8/trunk8.js')
require('../../bower_components/jquery-circle-progress/dist/circle-progress.js')
require('../../bower_components/matchHeight/dist/jquery.matchHeight.js')
require('../../bower_components/odometer/odometer.min.js')
require('../../bower_components/cloud9carousel/jquery.cloud9carousel.js')
require('../../bower_components/cookieconsent/build/cookieconsent.min.js')
window.text = '' // bugfix for ImageSelect (it has something to do with the way we minify js-files and global variables)
require('../../bower_components/image-select/src/ImageSelect.jquery.js')
require('../../bower_components/responsive-tabs/js/jquery.responsiveTabs.js')
require('../../bower_components/dotdotdot/dist/jquery.dotdotdot.js')
require('@bower_components/responsive-tabs')

heroslideshowInit()

require('./_checkbox-binding')
require('./_colorbox-button-fix')
require('./_dropdown-link')
require('./_greeter')
require('./_functions')
require('./_substitute')
require('./_ur-plus-account-information')
require('./_carousel')
require('./_swipeshow')
require('./_optionslider')
require('./_banner')
require('./_croppie')
require('./_hubspotTrack')
require('./_jumbotron')
require('./_languagepicker')
require('./_location')
require('./_verticalseperator')
require('./_verticalstepper')
require('./_parallax')
require('./_xchoicebutton')
require('./_fancyslider')
require('./_quote')
require('./_pagemenu')
require('./_anchor')
require('./_codeelement')
require('./_searchbar')
require('./_flowslider')
require('./_productslider')
require('./_compare')
require('./_showcase')
require('./_matchheight')
require('./_filtermenu')
require('./_module')
require('./_clipboard')
require('./_notifier')
require('./_formenabler')
require('./_sticky')

const panel = require('./_panel')
panel.init()

const popup = require('./_popup')
popup.init()

const stickyFooter = require('./_sticky-footer')
stickyFooter.init()

const header = require('./_header')
header.init()

const languagesuggestion = require('./_languagesuggestion')
languagesuggestion.init()

$(() => {
  ElementQueries.init()
  initializeVideoPopup()

  require('./_mobilemenu')
  require('./_loginbox')
})
