const $ = require('jquery')

export const checkAnimation = () => {
  $('.quote--effect').each(function (index, e) {
    const viewportTop = $(window).scrollTop()
    const viewportBottom = viewportTop + $(window).height()
    const distanceTop = $(e).offset().top
    const offset = $(window).outerHeight() * 0.2
    const inView = Math.max(viewportBottom - distanceTop, 0) > offset

    if (inView) {
      $(e).addClass('quote--effect--active')
    }
  })
}

$(() => {
  $(window).on('load resize scroll', function () {
    checkAnimation()
  })
})
