const $ = window.jQuery

$(() => {
  function showNotifier(element) {
    element.tooltip({
      items: '.notifier',
      position: { my: 'center bottom', at: 'center top-8' },
      classes: {
        'ui-tooltip': 'notifier-content ' + element.data('theme')
      },
      show: { effect: 'fadeIn', duration: 150 },
      hide: { effect: 'fadeOut', duration: 150 },
      content: function () {
        return element.data('tooltip')
      }
    })
    element.tooltip('open')
    element.unbind()
    setTimeout(function () {
      element.tooltip('close')
      $('.notifier').click(function () {
        showNotifier($(this))
      })
    }, 2200)
  }

  $('.notifier').click(function () {
    showNotifier($(this))
  })
})
