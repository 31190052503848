const $ = window.jQuery

$(() => {
  let elementPositions = []

  const updateInitialElementPositions = () => {
    elementPositions = []
    $('.jumbotron').each((i, e) => {
      const $element = $(e)
      elementPositions.push($element.offset().top - (parseInt($element.css('top'), 10) || 0))
    })
  }

  const render = () => {
    const windowPosition = $(window).scrollTop()

    $('.jumbotron-background-content').each((i, e) => {
      const $element = $(e)
      const elementPosition = elementPositions[i] + ($element.data('jumbotron-offset') || 0)
      const difference = windowPosition - elementPosition
      const percentageOfHeight =
        Math.max(0, difference / $element.outerHeight()) * ($element.data('jumbotron-speed') || 1)
      const scale = 1 + percentageOfHeight

      $element.css({
        transform: `scale(${scale})`,
        msTransform: `scale(${scale}, ${scale})`
      })
    })
  }

  updateInitialElementPositions()
  render()

  $(window).on('resize', () => {
    updateInitialElementPositions()
  })

  $(window).on('scroll', () => {
    render()
  })
})
