const $ = window.jQuery

$(() => {
  function initCarousel() {
    if (isMobileWidth() === true) {
      if ($('.carousel').hasClass('slick-initialized') === false) {
        $('.carousel').slick({
          centerMode: true,
          centerPadding: '80px',
          slidesToShow: 1,
          arrows: false
        })
      }
    } else {
      if ($('.carousel').hasClass('slick-initialized') === true) {
        $('.carousel').slick('unslick')
      }
    }
  }

  $(window).resize(function () {
    initCarousel()
  })

  function isMobileWidth() {
    return $('.mediaquery--tablet').is(':visible')
  }

  window.initCarousel = initCarousel

  initCarousel()
})
