module.exports = {
  init: () => {
    const isOverflowing = (el) => {
      const curOverflow = el.style.overflow

      if (!curOverflow || curOverflow === 'visible') {
        el.style.overflow = 'hidden'
      }

      const isOverflowing = el.clientWidth < el.scrollWidth - 1 || el.clientHeight < el.scrollHeight // the -1 is needed for Edge browsers
      el.style.overflow = curOverflow

      return isOverflowing
    }

    let maxOverflowAt = undefined

    const toggleOverflowLogo = (overflowing) => {
      const header = $('.header')
      const normalHeaderLogo = $('.header-logo:not(.header-overflow-logo)')
      const overflowHeaderLogo = $('.header--overflow-logo')

      if (overflowing) {
        normalHeaderLogo.addClass('grid-hidden')
        overflowHeaderLogo.removeClass('grid-hidden')
        header.addClass('header--overflow')
      } else {
        normalHeaderLogo.removeClass('grid-hidden')
        overflowHeaderLogo.addClass('grid-hidden')
        header.removeClass('header--overflow')
      }
    }

    const checkOverflowingHeader = () => {
      const $overflowElement = $('.header .header--overflow-row')
      if ($overflowElement.length < 1) return

      const overflowing = isOverflowing($overflowElement[0])
      const documentWidth = $(document).width()

      if (overflowing) {
        maxOverflowAt = Math.max(maxOverflowAt || 1, documentWidth)
      }

      if (maxOverflowAt != null && maxOverflowAt >= documentWidth) {
        toggleOverflowLogo(true)
      } else {
        toggleOverflowLogo(overflowing)
      }
    }

    $(() => {
      checkOverflowingHeader()
      $(window).resize(checkOverflowingHeader)
    })

    const elementInViewport = (el) => {
      const top = el.offsetTop
      const height = el.offsetHeight

      return height + top !== 0
    }

    $(() => {
      function isHeaderSticky() {
        return $('.mediaquery--burger').is(':visible')
      }

      var element = $('.header')

      const $document = $(document)
      const $window = $(window)

      let elTotalHeight = 0,
        elHeight = 0,
        elTop = 0,
        dHeight = 0,
        wHeight = 0,
        wScrollCurrent = 0,
        wScrollBefore = 0,
        wScrollDiff = 0,
        scrollTo = 0

      function headerUpdate() {
        const $pagemenu = $('.pagemenu-dummy')

        const disabled =
          element.hasClass('header--simple') || // disable on simple headers
          element.hasClass('header--static') || // class to make the header to always position to the top
          ($pagemenu.length > 0 && $pagemenu.offset().top < wScrollCurrent + $pagemenu.outerHeight()) // disable this effect, whenever there is a pagemenu on the page (these components are not compatible)

        elHeight = element.outerHeight()
        elTotalHeight = elHeight
        dHeight = $document.height()
        wHeight = $window.height()
        wScrollCurrent = $window.scrollTop()
        wScrollDiff = wScrollBefore - wScrollCurrent
        elTop = parseInt(element.css('top')) + wScrollDiff

        if (isHeaderSticky()) {
          if (wScrollCurrent > 0) {
            // page is scrolled a bit
            element.addClass('header--scrolled')
          } else if (wScrollCurrent <= 0) {
            // scrolled to the very top; element sticks to the top
            element.removeClass('header--scrolled')
          }
        } else if (!disabled) {
          if (wScrollCurrent <= 0) {
            // scrolled to the very top; element sticks to the top
            element.css('top', 0)
            element.removeClass('header--scrolled')
            element.removeClass('header--hidden')
          } else {
            scrollTo = 0
            if (wScrollDiff > 0) {
              // scrolled up; element slides in
              element.css('top', elTop > scrollTo ? scrollTo : elTop)
              element.removeClass('header--hidden')
              element.addClass('header--scrolled')
            } else if (wScrollDiff < 0) {
              // scrolled down
              element.css('top', Math.abs(elTop) > elTotalHeight ? -elTotalHeight : elTop)
              if (wScrollCurrent > elHeight) {
                $('.login').removeClass('login--active')
                if (!elementInViewport(element[0])) {
                  element.addClass('header--hidden')
                  element.removeClass('header--scrolled')
                }
              }
            }
          }
          wScrollBefore = wScrollCurrent
        }
      }

      headerUpdate()

      $window.on('scroll', headerUpdate)

      $(window).resize(function () {
        headerUpdate()
      })
    })
  }
}
