const $ = window.jQuery

$(() => {
  function initSwipeshow() {
    const $swipeshow = $('.swipeshow')
    const slides = $swipeshow.data('slides')

    if (slides > 4) {
      $('.swipeshow').addClass('swipeshow--hasnavigation')
    }

    $swipeshow.slick({
      slidesToShow: Math.min(4, slides),
      slidesToScroll: 4,
      appendArrows: $('.swipeshow-navigation'),
      appendDots: $('.swipeshow-control'),
      dots: slides > 4,
      arrows: slides > 4,
      slide: '.swipeshow-slide',
      swipe: slides > 4,
      accessibility: slides > 4,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: slides > 2,
            slidesToShow: Math.min(2, slides),
            slidesToScroll: 1,
            dots: slides > 2,
            swipe: slides > 2,
            accessibility: slides > 2
          }
        },
        {
          breakpoint: 481,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: slides > 1,
            swipe: slides > 1,
            accessibility: slides > 1
          }
        }
      ]
    })
  }

  initSwipeshow()
})
