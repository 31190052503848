const $ = window.jQuery

$(() => {
  let elementPositions = []

  const updateInitialElementPositions = () => {
    elementPositions = []
    $('.decorator-content').each((i, e) => {
      const $element = $(e)
      elementPositions.push($element.offset().top - parseInt($element.css('top'), 10))
    })
  }

  setTimeout(updateInitialElementPositions, 1)

  $(window).on('resize', () => {
    updateInitialElementPositions()
  })

  $(window).on('scroll', () => {
    const windowPosition = $(window).scrollTop()
    $('.decorator-content').each((i, e) => {
      const $element = $(e)
      const elementPosition = elementPositions[i]
      const speed = $element.data('parallax-speed') || 0.1
      const base = $element.data('parallax-base') || 0
      const difference = windowPosition - elementPosition

      $element.css({
        top: `${base + difference * speed}px`
      })
    })
  })
})
