const $ = window.jQuery

$(() => {
  if (window.Croppie) {
    const croppieElement = $('.croppie-wrapper').get(0)
    let croppieInstance = undefined
    $('#croppie-file').change((e) => {
      var files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return
      }

      if (croppieInstance) {
        croppieInstance.destroy()
      }

      croppieElement.addEventListener('update', () => {
        croppieInstance
          .result({
            type: 'base64',
            size: {
              width: 150,
              height: 150
            }
          })
          .then((r) => {
            $('#croppie64').val(r)
          })
      })

      croppieInstance = new Croppie(croppieElement, {
        url: window.URL.createObjectURL(files[0]),
        viewport: {
          width: 150,
          height: 150,
          type: 'square'
        }
      })

      $(croppieElement).parent().css({ height: 435 })
    })
  }
})
