const $ = window.jQuery

$(() => {
  // $('[data-bind-checked]').each((i, element) => {
  //   const checkbox = $(element)
  //   const boundElement = $(checkbox.data('bind-checked'))
  //   if (boundElement.length > 0) {
  //     boundElement.attr('type', 'text')
  //     boundElement.val('')
  //   }
  // })

  $('[data-bind-checked]').change(function () {
    const checkbox = $(this)
    const isChecked = checkbox.is(':checked')

    const boundElement = $(checkbox.data('bind-checked'))
    if (boundElement.length > 0) {
      boundElement.attr('checked', isChecked)
      // boundElement.attr('type', 'text')
      // console.log(boundElement.attr('type'));
      //
      // if (isChecked) {
      //   boundElement.val(checkbox.data('bind-checked-value'))
      // } else {
      //   boundElement.val('')
      // }
    }
  })
})
