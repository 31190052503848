const $ = window.jQuery
const debounce = require('lodash.debounce')

$(() => {
  var windowWidth = $(window).width()

  function updateShowcase() {
    $('.showcase').each((idx, elem) => {
      const $elem = $(elem)
      var $wrapper = $elem.closest('.showcase-outerwrapper')
      var wrapperWidth = $wrapper.width()
      var radiusY = 90
      var radiusX = wrapperWidth / 2 > 800 ? 800 : wrapperWidth / 2
      if (wrapperWidth < 600) {
        radiusX = wrapperWidth / 1.35
        radiusY = 70
      }
      if (wrapperWidth < 500) {
        radiusX = wrapperWidth / 1.25
        radiusY = 62
      }

      $elem.Cloud9Carousel({
        buttonLeft: $wrapper.find('.showcase-navigation--prev'),
        buttonRight: $wrapper.find('.showcase-navigation--next'),
        autoPlay: 0,
        speed: 1,
        bringToFront: true,
        farScale: 0.3,
        itemClass: 'showcase-item',
        frontItemClass: 'showcase-item--active',
        xRadius: radiusX,
        yRadius: radiusY,
        onRendered: function (carousel) {
          for (let i = 0; i < carousel.items.length; i++) {
            const factor = carousel.items[i].scale
            let opacity = factor + 0.2
            if (opacity > 1) {
              opacity = 1
            }
            $(carousel.items[i].element).css('opacity', opacity)

            if (factor < 0.4) {
              $(carousel.items[i].element).css('filter', 'blur(3px)')
            } else {
              $(carousel.items[i].element).css('filter', 'none')
            }

            var videoState = $(carousel.items[i].element).find('video')[0].readyState
            if (videoState === 4) {
              if (carousel.items[i].scale > 0.9965) {
                $(carousel.items[i].element).addClass('showcase-item--showvideo')
                $(carousel.items[i].element).find('video')[0].play()
              } else {
                $(carousel.items[i].element).removeClass('showcase-item--showvideo')
                $(carousel.items[i].element).find('video')[0].pause()
                $(carousel.items[i].element).find('video')[0].currentTime = 0
              }
            }
          }

          const oldIndex = $elem.data('index')
          const currentIndex = carousel.nearestIndex()
          if (currentIndex !== oldIndex) {
            const nearestItem = carousel.nearestItem()
            const $nearestElement = $(nearestItem.element)
            const currentTitle = $nearestElement.find('.showcase-item-info-title')
            const currentText = $nearestElement.find('.showcase-item-info-text')
            const currentLink = $nearestElement.find('.showcase-item-info-link')

            if (!$wrapper.find('.showcase-infoarea').is(':animated')) {
              const $titlearea = $wrapper.find('.showcase-titlearea')
              const $textarea = $wrapper.find('.showcase-textarea')
              const $linkarea = $wrapper.find('.showcase-linkarea')

              debugger

              if (
                currentTitle.text() !== $titlearea.text() ||
                currentText.text() !== $textarea.text() ||
                currentLink.text() !== $linkarea.text()
              ) {
                $('.showcase-infoarea')
                  .animate({ opacity: 0 }, 500, function () {
                    $titlearea.html(currentTitle.html())
                    $textarea.html(currentText.html())
                    $linkarea.html(currentLink.html())
                  })
                  .animate({ opacity: 1 }, 500)
              }
            }
          }
        }
      })

      $wrapper.find('.showcase-navigation').click(function () {
        $wrapper.find('video')[0].currentTime = 0
        $wrapper.find('video')[0].pause()
      })
    })
  }

  updateShowcase()

  const onResize = debounce(() => {
    const $elems = $('.showcase')
    $elems.each((idx, elem) => {
      const $elem = $(elem)
      $elem.data('carousel').deactivate()
    })
    updateShowcase()
  }, 60)

  $(window).resize(function () {
    if ($(window).width() !== windowWidth) {
      windowWidth = $(window).width()
      onResize()
    }
  })
})
