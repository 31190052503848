const $ = window.jQuery

$(() => {
  initBannerSlideshow()

  $(window).resize(function () {
    initBannerSlideshow()
  })

  function initBannerSlideshow() {
    if (isMobileWidth() === true) {
      if ($('.banner-slideshow').hasClass('slick-initialized') === false) {
        $('.banner-slideshow').slick({
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
          arrows: false,
          dots: true,
          initialSlide: 0,
          infinite: false
        })
      }
    } else {
      if ($('.banner-slideshow').hasClass('slick-initialized') === true) {
        $('.banner-slideshow').slick('unslick')
      }
    }
  }

  /*##################
  Media query check
  ##################*/

  function isMobileWidth() {
    return $('.mediaquery--mobile').is(':visible')
  }

  $(function () {
    $('.banner-group1 .banner-title').matchHeight({
      property: 'height'
    })
    $('.banner-group1').matchHeight({
      property: 'height'
    })

    $('.banner-group2 .banner-title').matchHeight({
      property: 'height'
    })
    $('.banner-group2').matchHeight({
      property: 'height'
    })
  })
})
