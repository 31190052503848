const $ = window.jQuery

$(() => {
  $('.optionslider').slick({
    dots: true,
    appendDots: '.optionslider-dots-content',
    customPaging: function (slick, index) {
      const $slide = $(slick.$slides[index])

      return `<div style="background-image:url('${$slide.data(
        'thumb'
      )}');"></div><div style="background-image:url('${$slide.data('thumb-big')}');"></div>`
    },
    arrows: false,
    slide: '.optionslider-slide',
    adaptiveHeight: true
  })
})
